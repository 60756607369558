import { createWeb3Modal, defaultConfig } from '@web3modal/ethers'
import { BrowserProvider, Contract, formatUnits } from 'ethers'
import * as ethers from 'ethers';
import {
    OptionsController
} from '@web3modal/core'

let modal = null;
export function initialized()
{
    return modal != null;
}
export async function connect() {
    await modal.open();
}
export async function initWallet()
{
    // First, check if the modal element needs to be removed or reset
    const existingModal = document.querySelector('w3m-modal');
    if (existingModal) {
        document.body.removeChild(existingModal);
    }

    await Promise.all([
        import('@web3modal/ui'),
        import('@web3modal/scaffold-ui/w3m-modal')
    ]);

    const modal2 = document.createElement('w3m-modal');
    if (!OptionsController.state.disableAppend) {
        document.body.insertAdjacentElement('beforeend', modal2);
    }

    const projectId = '0ca0aebf8a0e32fcb0714a8219825696'

    // 2. Set chains
    /*const mainnet = {
        chainId: 199,
        name: 'BitTorrent Chain Mainnet',
        currency: 'BTT',
        explorerUrl: 'https://bttcscan.com',
        rpcUrl: 'https://rpc.bt.io'
    }*/
    const mainnet = {
        chainId: 97,
        name: 'BNB Smart Chain Testnet',
        currency: 'BNB',
        explorerUrl: 'https://testnet.bscscan.com',
        rpcUrl: 'https://data-seed-prebsc-1-s1.bnbchain.org:8545'
    }

    // 3. Create your application's metadata object
    const metadata = {
        name: 'BlinkAI',
        description: 'Blockchain Link for AI',
        url: 'https://www.blinkai.xyz',
        icons: ['https://avatars.mywebsite.com/']
    }

    // 4. Create Ethers config
    const ethersConfig = defaultConfig({
        /*Required*/
        metadata,

        /*Optional*/
        enableEIP6963: true, // true by default
        enableInjected: true, // true by default
        enableCoinbase: true, // true by default
        rpcUrl: 'https://rpc.bt.io', // used for the Coinbase SDK
        defaultChainId: 97, // used for the Coinbase SDK
    })

    if (modal) {
        modal.disconnect();
    }

    // 5. Create a Web3Modal instance
    modal = createWeb3Modal({
        ethersConfig,
        chains: [mainnet],
        projectId,
        enableAnalytics: true, // Optional - defaults to your Cloud configuration
        enableOnramp: true, // Optional - false as default

    })

    // modal.subscribeEvents(state => { alert(`subscribeEvents ${JSON.stringify(state)}`) });
    // modal.subscribeWalletInfo(state => { alert(`subscribeWalletInfo ${JSON.stringify(state)}`) });
    // modal.subscribeProvider(state => { alert(`subscribeProvider ${JSON.stringify(state)}`) });
}

export async function execute(contractAddress, contractEntityAbi, method, parameters, id, deposit)
{
    if (!initialized())
    {
        return;
    }

    try{
        const walletProvider = modal.getWalletProvider();
    
        const provider = new BrowserProvider(walletProvider)
        const signer = await provider.getSigner()
    
        let contract = new ethers.Contract(contractAddress, contractEntityAbi, signer);
    
        if (deposit != null) {
            parameters.push({ value: deposit });
        }
        //else {
        //    parameters.push(0);
        //}

        var methodName = toMemberCase(method, true);

        var itemTransaction = await contract[methodName].apply(contract, parameters);
    
        var receipt = await itemTransaction.wait();
    
        const filteredLogs = receipt.logs;
    
        let logs = [];
        for (var logNr = 0; logNr < filteredLogs.length; logNr++) {
            try {
                let log = contract.interface.parseLog(filteredLogs[logNr]);
                if (log) {
                    logs.push(log);
                }
            } catch (logErr) {
                console.log("EthersJs issue, log processing failed: " + logErr.message)
                console.log(JSON.stringify(filteredLogs[logNr]))
            }
        }
    
        const itemUpdated = logs.find(event => event.eventName === 'ItemUpdated' || event.name === 'ItemUpdated');
        if (itemUpdated != null) {
            id = itemUpdated.args["_id"];
        }
    
        return { hash: receipt.hash, id: id?.toString() };
    }
    catch(err)
    {
        console.error(err);
        return { error: err.message }
    }
}

function toMemberCase(str, startLower, delimiter = "") {
  if (!str || /^\s*$/.test(str)) {
      return str;
  }

  // Replace invalid characters function, adapted to JavaScript
  function removeInvalidChars(input) {
      return input.replace(/[^a-zA-Z0-9\s]/g, ''); // This regex might need adjustments based on what is considered invalid
  }

  str = removeInvalidChars(str);
  let newWord = true;
  let result = '';

  for (let i = 0; i < str.length; i++) {
      let currentChar = str.charAt(i);

      if (/^[a-zA-Z]$/.test(currentChar)) { // Check if the character is a letter
          if (newWord) {
              newWord = false;
              currentChar = currentChar.toUpperCase();
          } else {
              currentChar = currentChar.toLowerCase();
          }

          if (i === 0) {
              if (startLower) {
                  currentChar = currentChar.toLowerCase();
              } else {
                  currentChar = currentChar.toUpperCase();
              }
          }

          result += currentChar;
      } else if (/\s/.test(currentChar)) { // Check if the character is a whitespace
          newWord = true;
          result += ' '; // Add a space which will be replaced later
      } else {
          result += currentChar;
      }
  }

  return result.replace(/\s+/g, delimiter); // Replace spaces with the delimiter
}

export async function signMessage() {
    if (!initialized()) {
        return { error: "Wallet not initialized" };
    }

    try {
        const walletProvider = modal.getWalletProvider();
        const provider = new BrowserProvider(walletProvider)
        const signer = await provider.getSigner()
        const message = `Please sign this message to confirm your identity. Nonce: ${Math.floor(Math.random() * 1000000)}`;

        const signature = await signer.signMessage(message);
        const address = await signer.getAddress();

        return { message, signature, address };
    } catch (err) {
        console.error(err);
        return { error: err.message };
    }
}

export async function getItem(contractAddress, contractAbi, id) {
    let contract = new ethers.Contract(contractAddress, contractAbi, new ethers.JsonRpcProvider("https://rpc.bt.io"));

    var itemRaw = await contract.getItem(id);
    var obj = itemRaw.toObject();
    var item = convertBigIntToString(obj);

    return JSON.stringify(item);
}

function convertBigIntToString(obj) {
  for (const key in obj) {
      if (typeof obj[key] === 'bigint') {
          // Convert bigint to string
          obj[key] = obj[key].toString();
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          // Recursively apply to nested objects
          convertBigIntToString(obj[key]);
      }
  }
  return obj;
}

export function getWalletStatus() {

    return {
        isConnected: modal?.getIsConnected() ?? false,
        address: modal?.getAddress() ?? null
    };
}
